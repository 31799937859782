import React from 'react';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import KeyIcon from '@mui/icons-material/Key';
import WebhookIcon from '@mui/icons-material/Webhook';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { Chip, Skeleton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import ErrorMessage from '../common/ErrorMessage';
import { useMyApplicationsQuery } from '../../generated/graphql';
import { CardRefreshAction } from '../common/CardRefreshAction';
import { ApplicationWebhooks } from './IntegrationInfo/ApplicationWebhooks';
import { GettingStartedText } from '../texts/guide';
import { IntegrationBadge } from './IntegrationInfo/IntegrationBadge';
import { SectionCardHeader } from '../common/SectionCardHeader';
import { Section } from '../layout/Section';
import { ApplicationKeys } from './IntegrationInfo/ApplicationKeys';
import { ApplicationUsersTable } from './IntegrationInfo/ApplicationUsersTable';
import { ApplicationOperations } from './IntegrationInfo/ApplicationOperations';

export function IntegrationList() {
  const {
    data, loading, error, refetch,
  } = useMyApplicationsQuery();

  const rows = data?.user.myApplications;

  return (
    <>
      {loading && <Section><LoadingSkeleton/></Section>}
      <ErrorMessage error={error}/>
      {rows?.length === 0 && (
        <Section>
          <Card>
            <Alert severity="info">
              <Typography variant='body1'>
                You have no applications associated with you yet.
              </Typography>
              <Typography variant='body2'>
                <GettingStartedText/>
              </Typography>
            </Alert>
          </Card>
        </Section>
      )}
      {rows && rows.map((row) => (
        <Section key={row.id}>
          <Card>
            <SectionCardHeader
              avatar={<BookmarkIcon color="secondary"/>}
              title={(
                <Grid container spacing={2} flexWrap="wrap">
                  <Grid item sx={{ fontWeight: 'bold' }}>{row.teamName}</Grid>
                  <Grid item>{row.appName}</Grid>
                  <Grid item sx={{ flexGrow: 1 }} data-testid="integrationName">{row.appId}</Grid>
                  <Grid item>
                    <ApplicationApprovalStatus approvedAt={row.approvedAt}/>
                  </Grid>
                </Grid>
              )}
              action={<CardRefreshAction onClick={refetch}/>}
            />
          </Card>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <AccordionSummaryContent left="Application Keys" right={(
                <IntegrationBadge
                  content={row.secrets.length || 0}
                  icon={<KeyIcon/>}
                />
              )}/>
            </AccordionSummary>
            <AccordionDetails>
              {!row.approvedAt && (
                <Box mb={4}>
                  <Alert severity="warning" variant="filled">
                    This application has not yet been approved by the administrators.
                    You can create app keys now, however they will not work
                    until the application has been approved
                  </Alert>
                </Box>
              )}
              <ApplicationKeys application={row} refresh={refetch}/>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <AccordionSummaryContent left="Operations" right={(
                <IntegrationBadge
                  content={row.operations.length}
                  icon={<PlayCircleOutlineIcon/>}
                />
              )}/>
            </AccordionSummary>
            <AccordionDetails>
              <ApplicationOperations application={row} refresh={refetch}/>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <AccordionSummaryContent left="Users" right={(
                <IntegrationBadge
                  content={row.users.length || 0}
                  icon={<PersonIcon/>}
                />
              )}/>
            </AccordionSummary>
            <AccordionDetails>
              <ApplicationUsersTable users={row.users} refresh={refetch}/>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <AccordionSummaryContent left="Webhooks" right={(
                <IntegrationBadge
                  content={row.webhooks.length || 0}
                  icon={<WebhookIcon/>}
                />
              )}/>
            </AccordionSummary>
            <AccordionDetails>
              <ApplicationWebhooks application={row} refresh={refetch}/>
            </AccordionDetails>
          </Accordion>
        </Section>
      ))}
    </>
  );
}

interface AccordionSummaryContentProps {
  left: JSX.Element | string,
  right: JSX.Element | string,
}

function AccordionSummaryContent(props: AccordionSummaryContentProps) {
  return (
    <Grid container>
      <Grid item sx={{ flexGrow: 1 }}>
        {props.left}
      </Grid>
      <Grid item sx={{ color: 'text.secondary' }} mr={2}>
        {props.right}
      </Grid>
    </Grid>
  );
}

function ApplicationApprovalStatus(props: { approvedAt: string | null }) {
  if (props.approvedAt) {
    return null;
  }

  return <Chip label="PENDING APPROVAL" color="warning" icon={<ErrorIcon/>} size="small"/>;
}

function LoadingSkeleton() {
  return (
    <>
      <Card>
        <SectionCardHeader
          avatar={<BookmarkIcon color="secondary"/>}
          title={(
            <Grid container spacing={2} flexWrap="wrap">
              <Grid item sx={{ flexGrow: 1 }}><Skeleton/></Grid>
              <Grid item sx={{ flexGrow: 1 }}><Skeleton/></Grid>
              <Grid item sx={{ flexGrow: 5 }}><Skeleton/></Grid>
            </Grid>
          )}
        />
      </Card>
      {[...Array(4)].map((e, i) => (
        <Accordion key={i}>
          <AccordionSummary>
            <AccordionSummaryContent
              left={<Skeleton/>}
              right={<Skeleton/>}
            />
          </AccordionSummary>
        </Accordion>
      ))}

    </>

  );
}
