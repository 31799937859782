/* eslint-disable max-len */
import React from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import { LoginPage } from '../pages/LoginPage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { MyApplicationsPage } from '../pages/MyApplicationsPage';
import { ControlPanelLayout } from './ControlPanelLayout';

import { ThemeTestPage } from '../pages/ThemeTestPage';
import { MarkdownFile } from '../common/MarkdownFile';
import graphqlMd from '../documentation/pages/user-guide/graphql.md';
import graphqlUiMd from '../documentation/pages/user-guide/graphql-ui.md';
import authenticationMd from '../documentation/pages/user-guide/authentication.md';
import { BreakdownCodeExamplePage } from '../documentation/pages/examples/variant-breakdown/BreakdownCodeExamplePage';
import { PnoBreakdownCodeExamplePage } from '../documentation/pages/examples/pno-breakdown/PnoBreakdownCodeExamplePage';
import { PreliminaryDataCodeExamplePage } from '../documentation/pages/examples/preliminary-data/PreliminaryDataCodeExamplePage';
import { EffectivityWeeksCodeExamplePage } from '../documentation/pages/examples/effectivity-weeks/EffectivityWeeksCodeExamplePage';
import { colors } from '../../services/mui-theme';
import { AdminPage } from '../pages/AdminPage';
import { GraphExplorerPage } from '../documentation/pages/model/GraphExplorerPage';
import { DataDashboardPage } from '../pages/DataDashboardPage';
import { UpstreamPage } from '../pages/UpstreamPage';
import {
  ConfigurationDictionaryCodeExamplePage,
} from '../documentation/pages/examples/configuration-dictionary/ConfigurationDictionaryCodeExamplePage';
import { BomLinesCodeExamples } from '../documentation/pages/examples/bom/BomLinesCodeExamples';
import { DataModelPage } from '../pages/DataModelPage';
import { EnvironmentsPage } from '../documentation/pages/user-guide/EnvironmentsPage';
import { FieldListPage } from '../pages/FieldListPage';
import { SupportPage } from '../documentation/pages/user-guide/SupportPage';
import { NewCastCasePage } from '../documentation/pages/cases/newcast/NewCastCasePage';
import { PartsCodeExamplePage } from '../documentation/pages/examples/parts/PartsCodeExamplePage';
import { SIPlusCasePage } from '../documentation/pages/cases/siplus/SIPlusCasePage';
import { EbomBrowserPage } from '../pages/browsers/EbomBrowserPage';
import { PartsBrowserPage } from '../pages/browsers/PartsBrowserPage';
import { ConfigurationDictionariesBrowserPage } from '../pages/browsers/ConfigurationDictionariesBrowserPage';
import { StoredCpvSetsBrowserPage } from '../pages/browsers/StoredCpvSetsBrowserPage';
import { ConsumptionRecordsBrowserPage } from '../pages/browsers/ConsumptionRecordsBrowserPage';
import { ChangeOrderIssuesBrowserPage } from '../pages/browsers/ChangeOrderIssuesBrowserPage';
import { Metrics } from '../pages/Metrics';
import { MfgChangeOrderIssuesBrowserPage } from '../pages/browsers/MfgChangeOrderIssuesBrowserPage';
import { BopRoutingBrowserPage } from '../pages/browsers/BopRoutingBrowserPage';
import { MfgAssemblyPlaceholderBrowserPage } from '../pages/browsers/MfgAssemblyPlaceholderBrowserPage';
import { EventsPage } from '../documentation/pages/user-guide/events-page/EventsPage';
import { MobilityBrowserPage } from '../pages/browsers/MobilityBrowserPage';
import { SoftwarePartsBrowserPage } from '../pages/browsers/SoftwarePartsBrowserPage';
import { ProductFamiliesBrowserPage } from '../pages/browsers/ProductFamiliesBrowserPage';
import { BestPracticePage } from '../documentation/pages/user-guide/best-practices-page/BestPracticePage';
import { RunOperationsPage } from '../admin/run-operations/RunOperationsPage';
import { BreakdownPerformanceTestPage } from '../pages/tools/BreakdownPerformanceTestPage';
import { AboutPage } from '../documentation/pages/user-guide/about-page/AboutPage';
import { UsageRuleBrowserPage } from '../pages/browsers/UsageRuleBrowserPage';
import { FeatureBreakdownPage } from '../pages/tools/FeatureBreakdownPage';
import { QuantityCheckPage } from '../pages/tools/QuantityCheckPage';
import { RevokedUsagesPage } from '../documentation/pages/business-logic/RevokedUsagesPage';
import { LinksPage } from '../documentation/pages/business-logic/LinksPage';
import { MbomPage } from '../documentation/pages/business-logic/MbomPage';
import { PreliminaryDataPage } from '../documentation/pages/business-logic/PreliminaryDataPage';
import { QuantityCheckSvcPage } from '../pages/tools/QuantityCheckSvcPage';
import { LmodBrowserPage } from '../pages/browsers/LmodBrowserPage';
import { FeatureBreakdownBatchPage } from '../pages/tools/FeatureBreakdownBatchPage';
import { HerculesCasePage } from '../documentation/pages/cases/hercules/HerculesCasePage';
import { QueryPage } from '../documentation/pages/query-filters/queries/QueryPage';
import { FilteringPage } from '../documentation/pages/query-filters/filtering/FilteringPage';
import { AggregationsPage } from '../documentation/pages/query-filters/aggregations/AggregationsPage';
import { SortingPage } from '../documentation/pages/query-filters/sorting/SortingPage';
import { CursorPaginationPage } from '../documentation/pages/query-filters/pagination/CursorPaginationPage';
import { OffsetPaginationPage } from '../documentation/pages/query-filters/pagination/OffsetPaginationPage';
import { MbomBrowserPage } from '../pages/browsers/MbomBrowserPage';
import { PmlsPage } from '../documentation/pages/business-logic/PmlsPage';
import { UpstreamDashboardPage } from '../pages/UpstreamDashboardPage';
import { JobOverviewPage } from '../pages/JobOverviewPage';
import { UploadDataValidation } from '../control-panel/DataQuality/DataQualityDashboard';
import {
  StoredCpvSetCodeExamplePage,
} from '../documentation/pages/examples/stored-cpv-set/StoredCpvSetCodeExamplePage';
import { SparePartInfoBrowserPage } from '../pages/browsers/SparePartInfoBrowserPage';
import { DataCleansingPage } from '../documentation/pages/business-logic/DataCleansingPage';
import { ComponentMbomPage } from '../documentation/pages/business-logic/ComponentMbomPage';
import { DataCleansingOverviewPage } from '../pages/DataCleansingPage';
import { TestSeriesConsumptionsBrowserPage } from '../pages/browsers/TestSeriesConsumptionsBrowserPage';
import { CmpConsumptionsFetcher } from '../tools/consumptions-fetcher/CmpConsumptionsFetcher';

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LoginPage/>}/>
        <Route path="control-panel" element={<ControlPanelLayout background={colors.primary}/>}>
          <Route index element={<MyApplicationsPage/>}/>
          <Route path="upstream-dashboard" element={<UpstreamDashboardPage/>}/>
          <Route path="dataQuality" element={<UploadDataValidation/>}/>
          <Route path="upstream" element={<UpstreamPage/>}/>
          <Route path="administration" element={<AdminPage/>}/>
          <Route path="run-operations" element={<RunOperationsPage/>}/>
          <Route path="metrics" element={<Metrics/>}/>
          <Route path="jobs" element={<JobOverviewPage/>}/>
          <Route path="data-cleansing" element={<DataCleansingOverviewPage/>}/>
        </Route>
        <Route path="graph-navigator" element={<ControlPanelLayout background={colors.primary} />}>
          <Route index element={<GraphExplorerPage />} />
        </Route>
        <Route path="data-model" element={<ControlPanelLayout />}>
          <Route index element={<DataModelPage />} />
          <Route path={'data'} element={<DataDashboardPage />} />
          <Route path={'field-list'} element={<FieldListPage />} />
        </Route>
        <Route path="documentation" element={<ControlPanelLayout footer />}>
          <Route path="theme-test" element={<ThemeTestPage />} />

          <Route path={''} element={<AboutPage />} />

          <Route path={'graphql'} element={<MarkdownFile file={graphqlMd} />} />
          <Route path={'graphql-ui'} element={<MarkdownFile file={graphqlUiMd} />} />
          <Route path={'authentication'} element={<MarkdownFile file={authenticationMd} />} />
          <Route path={'best-practice'} element={<BestPracticePage />} />
          <Route path={'environments'} element={<EnvironmentsPage />} />
          <Route path={'events'} element={<EventsPage />} />
          <Route path={'support'} element={<SupportPage />} />

          <Route path={'parts-code-example'} element={<PartsCodeExamplePage />} />
          <Route path={'breakdown-code-example'} element={<BreakdownCodeExamplePage />} />
          <Route path={'pno-breakdown-code-example'} element={<PnoBreakdownCodeExamplePage />} />
          {/* <Route path={'delta-updates-code-example'} element={<DeltaUpdatesCodeExamplePage/>}/> */}
          <Route path={'preliminary-data-code-example'} element={<PreliminaryDataCodeExamplePage />} />
          <Route path={'effectivity-weeks-code-example'} element={<EffectivityWeeksCodeExamplePage />} />
          <Route path={'bom-lines-code-example'} element={<BomLinesCodeExamples />} />
          <Route path={'configuration-dictionary-code-example'} element={<ConfigurationDictionaryCodeExamplePage />} />
          <Route path={'stored-cpv-set-code-example'} element={<StoredCpvSetCodeExamplePage />} />

          {/* -- Concepts and Explanations -- */}
          <Route path={'links'} element={<LinksPage />} />
          <Route path={'revoked-part-usages'} element={<RevokedUsagesPage />} />
          <Route path={'released-preliminary-data'} element={<PreliminaryDataPage />} />
          <Route path={'mbom'} element={<MbomPage />} />
          <Route path={'component-mbom'} element={<ComponentMbomPage />} />
          <Route path={'pmls'} element={<PmlsPage />} />
          <Route path={'data-cleansing-docs'} element={<DataCleansingPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="query-filtering" element={<ControlPanelLayout footer />}>
          <Route path="queries" element={<QueryPage />} />
          <Route path="filtering" element={<FilteringPage />} />
          <Route path="aggregations" element={<AggregationsPage />} />
          <Route path="sorting" element={<SortingPage />} />
          <Route path="offset-pagination" element={<OffsetPaginationPage />} />
          <Route path="cursor-pagination" element={<CursorPaginationPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="cases" element={<ControlPanelLayout background={colors.primary} />}>
          <Route path={'newcast'} element={<NewCastCasePage />} />
          <Route path={'siplus'} element={<SIPlusCasePage />} />
          <Route path={'hercules'} element={<HerculesCasePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="browser" element={<ControlPanelLayout background={colors.brand.blue3} />}>
          <Route path={'ebom'} element={<EbomBrowserPage />} />
          <Route path={'mbom'} element={<MbomBrowserPage />} />
          <Route path={'parts'} element={<PartsBrowserPage />} />
          <Route path={'software-parts'} element={<SoftwarePartsBrowserPage />} />
          <Route path={'lmod'} element={<LmodBrowserPage />} />
          <Route path={'usage-rules'} element={<UsageRuleBrowserPage />} />
          <Route path={'change-order-issues'} element={<ChangeOrderIssuesBrowserPage />} />
          <Route path={'configuration-dictionaries'} element={<ConfigurationDictionariesBrowserPage />} />
          <Route path={'consumption-records'} element={<ConsumptionRecordsBrowserPage />} />
          <Route path={'test-series-consumptions'} element={<TestSeriesConsumptionsBrowserPage />} />
          <Route path={'stored-cpv-sets'} element={<StoredCpvSetsBrowserPage />} />
          <Route path={'mobility'} element={<MobilityBrowserPage />} />
          <Route path={'product-families'} element={<ProductFamiliesBrowserPage />} />
          <Route path={'spare-part-info'} element={<SparePartInfoBrowserPage />} />

          {/* MFG */}
          <Route path={'mfg-change-order-issues'} element={<MfgChangeOrderIssuesBrowserPage />} />
          <Route path={'mfg-assembly-placeholders'} element={<MfgAssemblyPlaceholderBrowserPage />} />
          <Route path={'bop-routings'} element={<BopRoutingBrowserPage />} />
        </Route>
        <Route path="tools" element={<ControlPanelLayout background={colors.brand.green3} />}>
          <Route path={'breakdown-performance'} element={<BreakdownPerformanceTestPage />} />
          <Route path={'feature-breakdown'} element={<FeatureBreakdownPage />} />
          <Route path={'feature-breakdown-batch'} element={<FeatureBreakdownBatchPage />} />
          <Route path={'quantity-check'} element={<QuantityCheckPage />} />
          <Route path={'quantity-check-svc'} element={<QuantityCheckSvcPage />} />
          <Route path={'cmp-consumptions-counter'} element={<CmpConsumptionsFetcher />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
