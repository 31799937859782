export const webhooks = [
  {
    name: 'ReleasedBomDataUpdated',
    label: 'Released BOM data updated',
    description: 'Triggered when Released “Part Usages”, “Consumption Records”, “Parts”, “Part Constituents” and connected documents e.t.c are created or updated',
  },
  {
    name: 'PreliminaryBomDataUpdated',
    label: 'Preliminary Bom data updated',
    description: 'Triggered when Preliminary “Part Usages”, “Consumption Records”, “Parts”, “Part Constituents” and connected documents e.t.c are created or updated',
  },
  {
    name: 'StoredCpvSetDataUpdated',
    label: 'Stored CPV set data updated',
    description: 'Triggered when “Stored CPV Set data” (a.k.a SVC) data is created or changed',
  },
  {
    name: 'ConfigurationParametersUpdated',
    label: 'Configuration Dictionary, Parameter, Parameter Value or Commercial Packages Changed',
    description: 'Triggered when ConfigurationDictionary, ConfigurationParameter, ConfigurationParameterValues or CommercialPackages data is created or changed',
  },
  {
    name: 'ConfigurationRuleUpdated',
    label: 'ConfigurationRule Changed',
    description: 'Triggered when ConfigurationRule data is created or changed',
  },
  {
    name: 'MfgChangeOrderIssueUpdated',
    label: 'MfgChangeOrderIssue created or updated',
    description: 'Triggered when MfgChangeOrderIssue data is created or changed, affecting BOP and/or Component MBOM',
  },
  {
    name: 'SparePartInfoUpdated',
    label: 'Spare part data updated',
    description: 'Triggered when Released SparePartInfo is created or updated',
  },
];

export const webhookLabel = (name: string) => webhooks.find((w) => w.name === name)?.label || '-';
