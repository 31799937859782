import React from 'react';
import {
  Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { useBrowseBomInstancesQuery } from '../../../../../../generated/graphql';
import ErrorMessage from '../../../../../common/ErrorMessage';

export function BomInstanceSelect(props: { onSelect: (value: string) => void }) {
  const { data, loading, error } = useBrowseBomInstancesQuery();
  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <LinearProgress/>;
  }

  if (!data) {
    return null;
  }

  return (
      <FormControl fullWidth>
        <InputLabel>Bom Instance</InputLabel>
        <Select
          label="BomInstance"
          onChange={(event: SelectChangeEvent) => props.onSelect(event.target.value)}
          defaultValue={''}
        >
          {data.bomInstances.map((b, i) => (
            <MenuItem key={i} value={b.typeDesignation}>
              <Box sx={{ display: 'inline-block', width: 190 }}><small>typeDesignation:</small> {b.typeDesignation}</Box>
              <Box sx={{ display: 'inline-block', width: 120 }}><small>typeCode:</small> {b.productContext?.typeCode}</Box>
              <Box sx={{ display: 'inline-block', width: 190 }}><small>name:</small> {b.productContext?.name}</Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
