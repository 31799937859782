import { ValidAppEnvironment } from '../generated/graphql';
import { EnvironmentOption } from '../util/envrionments';

export const config = {
  defaultEnv: 'prod', // This is the default
  endpoints: [
    {
      key: 'prod',
      name: 'Production',
      graphQLEndpoint: 'https://prins-prod.azurewebsites.net/',
      disabled: false,
    },
    {
      key: 'qa',
      name: 'QA',
      graphQLEndpoint: 'https://prins-qa.azurewebsites.net/',
      disabled: false,
    },
    {
      key: 'preProd',
      name: 'Pre-production',
      graphQLEndpoint: 'https://prins-qa-preprod.azurewebsites.net/',
      disabled: false,
    },
    {
      key: 'dev',
      name: 'development',
      graphQLEndpoint: 'https://as-prins-graphhub-4497-dev.azurewebsites.net',
      disabled: false,
    },
    {
      key: 'test',
      name: 'Test',
      graphQLEndpoint: 'https://as-prins-graphhub-4497-test.azurewebsites.net',
      disabled: false,
    },
    {
      key: 'nr',
      name: 'NR',
      graphQLEndpoint: 'https://as-prins-graphhub-4497-plmnr.azurewebsites.net',
      disabled: false,
    },
  ],
  environments: [
    {
      label: 'Prod',
      environment: ValidAppEnvironment.Production,
    },
    {
      label: 'Pre-prod',
      environment: ValidAppEnvironment.PreProd,
    },
    {
      label: 'QA',
      environment: ValidAppEnvironment.Qa,
    },
    {
      label: 'Dev',
      environment: ValidAppEnvironment.Development,
    },
    {
      label: 'Test',
      environment: ValidAppEnvironment.Test,
    },
    {
      label: 'Nr',
      environment: ValidAppEnvironment.Nr,
    },
  ] as EnvironmentOption[],
  authConf: {
    clientId: '57487d25-b9a7-4c71-ae6d-1576c35b9d1d',
    tenant: '81fa766e-a349-4867-8bf4-ab35e250a08f',
  },
};
